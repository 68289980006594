// @ts-nocheck
import axios from 'axios';

const CONTENT_TYPE = 'Content-Type';

const JSON_CONTENT_TYPE = 'application/json';

const parseBody = (response) => response.data;

const parseHeadersAndBody = (response) => ({
  body: response.data,
  headers: response.headers,
});

const setHeadersIfEmpty = (headers, { instance, locale }) => {
  const changedHeaders = { ...headers };

  changedHeaders.locale = headers.locale || locale;
  changedHeaders[CONTENT_TYPE] = headers[CONTENT_TYPE] || JSON_CONTENT_TYPE;

  if (instance && !headers.instance) {
    changedHeaders.instance = instance;
  }

  return changedHeaders;
};

const withDefaultConfigs = (baseURL, instance, locale) => (config) => {
  // tslint:disable-next-line: restrict-plus-operands
  config.url = baseURL + config.url;
  config.headers.common = setHeadersIfEmpty(config.headers.common, {
    instance,
    locale,
  });
  return config;
};

export default function createRequest({ axiosInstance = axios.create(), baseUrl = '', instance, locale = 'en' }) {
  axiosInstance.interceptors.request.use(withDefaultConfigs(baseUrl, instance, locale), (err) => Promise.reject(err));

  const prepareAxiosConfig = (path, config) => ({
    ...config,
    url: path.toString(),
    data: config.data || {}, // Cannot be null or undefined as per https://github.com/axios/axios/issues/86
  });

  const request = (path, { parseHeaders = false, ...config } = {}) => {
    return axiosInstance(prepareAxiosConfig(path, config))
      .then(parseHeaders ? parseHeadersAndBody : parseBody)
      .catch((e) => Promise.reject(e.response));
  };

  request.get = (path, data, config) => request(path, { ...config, method: 'GET', data });
  request.post = (path, data, config = {}) => request(path, { ...config, method: 'POST', data });
  request.put = (path, data, config = {}) => request(path, { ...config, method: 'PUT', data });
  request.patch = (path, data, config) => request(path, { ...config, method: 'PATCH', data });
  request.delete = (path, config) => request(path, { ...config, method: 'DELETE' });

  request.uploadFile = (file, credentials) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('media_type', 'picture');
    formData.append('upload_token', credentials.uploadToken);
    formData.append('parent_folder_id', credentials.folderId);

    return fetch(credentials.uploadUrl, {
      method: 'POST',
      body: formData,
    }).then(parseBody);
  };

  return request;
}
