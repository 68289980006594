import { ComponentRef, EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import { PROFILE_CARD_APP_DEF_ID } from '../constants/apps';
import createRequest from './create-request';
import { log } from './monitoring';
import { adiLayoutFix } from './dataFixers';

const BASE_URL = '/_api/settings';

enum MigrationType {
  COMP_SETTING_FIX = '0',
  ADI_LAYOUT_FIX = '1',
}

interface MongoSettings {
  pictureStyle: number;
  profileLayout: number;
  showCover: boolean;
  styleParams?: {
    booleans?: object;
    numbers?: object;
  };
}

const getPictureStyleParam = (value: number) => ({
  type: 'number' as 'number',
  key: 'pictureStyle',
  param: {
    value,
  },
});

const getShowCoverParam = (value: boolean) => ({
  type: 'boolean' as 'boolean',
  key: 'showCover',
  param: {
    value,
  },
});
const PROFILE_APP_COMPONENT = 'profile';

const getMainMembersPageRef = async ({ editorSDK }: { editorSDK: EditorSDK }) => {
  const allPages = await editorSDK.document.pages.data.getAll('');
  const mainPage = allPages.find((page: { tpaPageId: string }) => page.tpaPageId === 'member_info');
  // @ts-ignore
  return (mainPage && { id: mainPage.id }) as PageRef;
};

const enum ViewMode {
  Editor = 'editor',
  Preview = 'preview',
  Site = 'site',
}

const requestFactory = (instance: string) =>
  createRequest({
    instance,
    baseUrl: 'https://apps.wix.com/members-area/',
  });

const getTpaCompRefs = async (editorSDK: EditorSDK) => {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId('', PROFILE_CARD_APP_DEF_ID);
  const allComps = await editorSDK.tpa.app.getAllCompsByApplicationId('', applicationId);

  return allComps;
};

const getMongoSettings = async (editorSDK: EditorSDK, tpaCompRef: ComponentRef, viewMode?: ViewMode) => {
  const instance = await editorSDK.info.getAppInstance('');
  const isSitePublished = await editorSDK.info.isSitePublished('');

  if (!viewMode) {
    viewMode = isSitePublished ? ViewMode.Site : ViewMode.Editor;
  }
  const request = requestFactory(instance);
  const appComponent = PROFILE_APP_COMPONENT;
  const compId = tpaCompRef.id;
  const originCompId = null;

  return request(
    `${BASE_URL}/component?viewMode=${viewMode}&compId=${compId}&appComponent=${appComponent}&originCompId=${originCompId}`
  );
};

const setMongoSettings = async (editorSDK: EditorSDK, tpaCompRef: ComponentRef, setting: Partial<MongoSettings>) => {
  const instance = await editorSDK.info.getAppInstance('');
  const isSitePublished = await editorSDK.info.isSitePublished('');

  const request = requestFactory(instance);
  const appComponent = PROFILE_APP_COMPONENT;
  const compId = tpaCompRef.id;

  await request.put(
    `${BASE_URL}/merge/component?appComponent=${appComponent}&compId=${compId}&viewMode=${ViewMode.Editor}`,
    setting
  );
  if (isSitePublished) {
    await request.put(
      `${BASE_URL}/merge/component?appComponent=${appComponent}&compId=${compId}&viewMode=${ViewMode.Site}`,
      setting
    );
  }
};

const setShowCoverParam = async (editorSDK: EditorSDK, compRef: ComponentRef, mongoSettings: MongoSettings) => {
  await editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [getShowCoverParam(mongoSettings.showCover)],
  });
  const newMongoSettings = {
    styleParams: {
      ...mongoSettings.styleParams,
      booleans: {
        // eslint-disable-next-line prettier/prettier
        ...mongoSettings.styleParams?.booleans,
        showCover: mongoSettings.showCover,
      },
    },
  };
  await setMongoSettings(editorSDK, compRef, newMongoSettings);
};

const setPictureStyleParam = async (editorSDK: EditorSDK, compRef: ComponentRef, mongoSettings: MongoSettings) => {
  await editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [getPictureStyleParam(mongoSettings.pictureStyle)],
  });

  const newMongoSettings = {
    styleParams: {
      ...mongoSettings.styleParams,
      numbers: {
        ...mongoSettings.styleParams?.numbers,
        pictureStyle: mongoSettings.pictureStyle,
      },
    },
  };
  await setMongoSettings(editorSDK, compRef, newMongoSettings);
};

async function compSettingsFix(editorSDK: EditorSDK) {
  try {
    const membersPageRef = await getMainMembersPageRef({ editorSDK });
    // @ts-ignore
    await editorSDK.pages.navigateTo('', { pageRef: membersPageRef });
    const tpaCompRefs = (await getTpaCompRefs(editorSDK)) ?? [];

    for (const tpaCompRef of tpaCompRefs) {
      const compRef = await editorSDK.components.getById('', { id: tpaCompRef.id });
      const mongoSettings = await getMongoSettings(editorSDK, tpaCompRef);
      const editorCompSettings = await editorSDK.document.tpa.getStyleParams('', { compRef });
      const showCoverMismatch =
        mongoSettings?.showCover && mongoSettings.showCover !== editorCompSettings?.booleans.showCover;
      const pictureStyleMismatch =
        mongoSettings?.pictureStyle === 1 && mongoSettings.pictureStyle !== editorCompSettings?.numbers.pictureStyle;

      if (showCoverMismatch) {
        await setShowCoverParam(editorSDK, compRef, mongoSettings);
      }

      if (pictureStyleMismatch) {
        await setPictureStyleParam(editorSDK, compRef, mongoSettings);
      }

      if (!showCoverMismatch && !pictureStyleMismatch) {
        log('Comp settings migration skipped. No settings mismatch');
      }
    }
  } catch (e) {
    log(`Error in handleMigrate(). ${e.toString()} ${e?.stack ?? ''}`);
    throw e;
  }
}

export async function handleMigrate(
  editorSDK: EditorSDK,
  { migrationId }: { migrationId?: string },
  isADI: boolean,
  allowHorizontalInADI: boolean
) {
  if (migrationId === MigrationType.COMP_SETTING_FIX) {
    await compSettingsFix(editorSDK);
  } else if (migrationId === MigrationType.ADI_LAYOUT_FIX) {
    await adiLayoutFix(editorSDK, isADI, allowHorizontalInADI);
  }
}
