import { getLayoutType, setVerticalLayoutStyleForProfileWidget } from './styles';
import { log } from './monitoring';
import { EditorSDK, PageRef } from '@wix/platform-editor-sdk';

const getMainMembersPageRef = async ({ editorSDK }: { editorSDK: EditorSDK }) => {
  const allPages = await editorSDK.document.pages.data.getAll('');
  const mainPage = allPages.find((page: { tpaPageId: string }) => page.tpaPageId === 'member_info');
  // @ts-ignore
  return (mainPage && { id: mainPage.id }) as PageRef;
};

export async function adiLayoutFix(editorSDK: EditorSDK, isADI: boolean, allowHorizontalInADI: boolean) {
  try {
    const membersPageRef = await getMainMembersPageRef({ editorSDK });
    // @ts-ignore
    await editorSDK.pages.navigateTo('', { pageRef: membersPageRef });
    const layoutType = await getLayoutType(editorSDK);
    if (isADI && !allowHorizontalInADI && layoutType === 1) {
      log('Fixing horizontal layout in ADI');
      await setVerticalLayoutStyleForProfileWidget(editorSDK);
    }
  } catch (e) {
    log(JSON.stringify(e));
  }
}
