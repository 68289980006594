import WixExperiments from '@wix/wix-experiments';
import { Experiments, MA_EXPERIMENTS_SCOPE } from '../constants/experiments';

let conductedExperiments: WixExperiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new WixExperiments({ scope: MA_EXPERIMENTS_SCOPE });
    await conductedExperiments.ready();
  }
}

export async function isADIHorizontalEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get(Experiments.EnableADIHorizontalLayout) === 'true';
}
