import { EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';
import { setNewLayoutStyleForProfileWidget, getLayoutType } from './services/styles';
import { toMonitored, initializeMonitoring } from './services/monitoring';
import { createAppAPI } from './services/app-api';
import { handleMigrate } from './services/migration';
import { isADIHorizontalEnabled } from './services/experiments';

let editorSDK: EditorSDK;
let isADI: boolean;
let allowHorizontalInADI: boolean;

export const editorScript: EditorPlatformApp = {
  editorReady: async (editorSDK_, _, options) => {
    editorSDK = editorSDK_;
    await initializeMonitoring(editorSDK, options);
    await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));
    allowHorizontalInADI = await isADIHorizontalEnabled();
    isADI = options.origin.type.includes('ADI');

    if (!options.firstInstall) {
      return;
    }
    const isHorizontalLayout = !isADI || allowHorizontalInADI;

    if (isHorizontalLayout) {
      await toMonitored('setHorizontalLayout', () => setNewLayoutStyleForProfileWidget(editorSDK));
    }
  },
  handleAction: async (args) => {
    const type = args.type;
    const payload = args.payload || {};

    try {
      switch (type) {
        case 'migrate':
          return toMonitored('handleMigrate', () => handleMigrate(editorSDK, payload, isADI, allowHorizontalInADI));
        default:
          return;
      }
    } catch (e) {
      console.error(e);
    }
  },
  exports: {
    isHorizontalLayout: () => getLayoutType(editorSDK),
  },
};
